import React from 'react';
import classes from './blurredBg.module.css';

const BlurredBg = (props) =>{
    return(
        <div className={classes.blurredElement}>
            <div className={classes.dimDown} />
            {props.children}
        </div>
    );
}

export default BlurredBg;