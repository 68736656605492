const initialState = {
  isLogged: true,
  user: null,
  locations: null,
  users: null,
};

const reducer = (state = initialState, action) => {
  if (action.type === "CHANGE_LOGGED_STATUS") {
    return {
      ...state,
      isLogged: action.payload,
    };
  }
  if (action.type === "CHANGE_USER") {
    return {
      ...state,
      user: action.payload,
    };
  }
  if (action.type === "UPDATE_LOCATIONS") {
    return {
      ...state,
      locations: action.payload,
    };
  }
  if (action.type === "UPDATE_USERS") {
    return {
      ...state,
      users: action.payload,
    };
  }
  return state;
};

export default reducer;
