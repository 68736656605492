import React from "react";
import LocationItem from "../../../commons/LocationItem/locationItem";
import locations from "../../../commons/ListOfLocations";

const LocationsList = (props) => {
  return (
    <React.Fragment>
      <div className={props.locationsListClass}>
        {props.locations.length === 0 ? (
          props.noResults ? (
            <div className="nores">אין מיקומים</div>
          ) : (
            <div className="loading">טוען את המיקומים...</div>
          )
        ) : (
          props.locations.map((location, index) => {
            return (
              <LocationItem
                title={location.properties.title}
                content={location.properties.content}
                type={location.properties.locationType}
                coordinates={location.geometry.coordinates}
                key={location._id}
                id={location._id}
                expires={location.properties.expiresAt}
              />
            );
          })
        )}
      </div>

      <a href="/manage/locations/add" className={props.newLocationButtonClass}>
        +
      </a>
    </React.Fragment>
  );
};

export default LocationsList;
