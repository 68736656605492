const alertDialog = (text, title, button) => `
    <div class="dialog">
        <div class="title">
            ${title}
        </div>
        <div class="content">
            ${text}
        </div>
        <button class="yes" onclick="hideElement(this);">${button}</button>
    </div>
`;

const defaultAlertOptions = {
  title: "",
  button: "אישור",
};

export const showAlert = (text, options = {}) => {
  let newOptions = { ...defaultAlertOptions, ...options };
  const body = document.body;
  const alertElement = document.createElement("div");
  alertElement.classList.add("dialog-area");
  alertElement.addEventListener("click", (element) => {
    const blackArea = element.target;
    if (blackArea.classList.contains("dialog-area")) {
      blackArea.remove();
    }
  });
  alertElement.innerHTML += alertDialog(
    text,
    newOptions.title,
    newOptions.button
  );
  body.append(alertElement);
};
const defaultConfirmOptions = {
  title: "",
  yes: "אישור",
  no: "ביטול",
};

export const showConfirm = (text, options = {}) => {
  return new Promise((resolve) => {
    let newOptions = { ...defaultConfirmOptions, ...options };
    const body = document.body;

    const alertElement = document.createElement("div");
    alertElement.classList.add("dialog-area");
    alertElement.addEventListener("click", (e) => {
      if (e.target.classList.contains("dialog-area")) {
        e.target.remove();
        resolve(false);
      }
    });

    const dialog = document.createElement("div");
    dialog.classList.add("dialog");

    const title = document.createElement("div");
    title.classList.add("title");
    title.innerText = newOptions.title;
    dialog.append(title);

    const content = document.createElement("div");
    content.classList.add("content");
    content.innerText = text;
    dialog.append(content);

    const yes = document.createElement("button");
    yes.classList.add("yes");
    yes.innerText = newOptions.yes;
    yes.addEventListener("click", (e) => {
      e.target.parentNode.parentNode.remove();
      resolve(true);
    });
    dialog.append(yes);

    const no = document.createElement("button");
    no.classList.add("no");
    no.innerText = newOptions.no;
    no.addEventListener("click", (e) => {
      e.target.parentNode.parentNode.remove();
      resolve(false);
    });
    dialog.append(no);

    alertElement.append(dialog);
    body.append(alertElement);
  });
};
