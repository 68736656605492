import React from 'react';
import classes from './navbar.module.scss';
import Locations, {Names} from '../../commons/ListOfLocations';


const Header = (props) => {
    const buttons = 
    (
        <React.Fragment>
            {Object.keys(Locations).map((location,index) =>{
                if(Locations[location].public){
                    return  (<button onClick={() => props.click(location)} key={location}>{Locations[location].name}</button>)
                }
            })}
        </React.Fragment>
    );


    return (
        <React.Fragment>
            <div className={classes.header}>
                {buttons}
            </div>
        </React.Fragment>
    );
}

export default Header;