import React, { Component } from "react";
import "./user.scss";

import { updateUser, deleteUser } from "../../../../commons/Database";
import { showAlert, showConfirm } from "../../../../helpers/alert";

class User extends Component {
  state = {
    name: this.props.name,
    email: this.props.email,
    role: this.props.role,
    showForm: false,
    canClickDelete: true,
    canClick: true,
  };

  getRoleName = (role) => {
    if (role === 0) {
      return "ללא הרשאות";
    } else if (role === 1) {
      return "עורך תוכן";
    } else {
      return "מנהל מערכת";
    }
  };

  nameChangeHandler = (name) => {
    this.setState({ name });
  };

  roleChangeHandler = (role) => {
    this.setState({ role: parseInt(role) });
  };

  toggleForm = () => {
    const newFormState = !this.state.showForm;
    this.setState({ showForm: newFormState });
  };

  deleteUser = async () => {
    if (await showConfirm("זוהי פעולה שלא ניתן לשחזר", { title: "בטוח?" })) {
      this.setState({ canClickDelete: false });
      await deleteUser(this.props.id);
      this.setState({ canClickDelete: true });
      showAlert("ֿהמשתמש נמחק");
    }
  };

  updateUser = async () => {
    this.setState({ canClick: false });
    await updateUser(this.props.id, {
      name: this.state.name,
      role: this.state.role,
    });
    this.setState({ canClick: true });
    showAlert(
      "כדי שהמשתמש ירגיש בשינויים, עליו לעשות ריפרש או להתנתק ולהתחבר מחדש.",
      { title: "המשמש עודכן" }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="user" onClick={() => this.toggleForm()}>
          <div className="infoItem">{this.props.name}</div>
          <div className="infoItem">{this.props.email}</div>
          <div className="infoItem">{this.getRoleName(this.props.role)}</div>
        </div>
        {this.state.showForm ? (
          <div className="userForm">
            <h1>עריכת משתמש</h1>
            <form>
              <label htmlFor="name">שם מלא</label>
              <input
                type="text"
                id="name"
                name="name"
                value={this.state.name}
                onChange={(e) => this.nameChangeHandler(e.target.value)}
              />
              <label htmlFor="email">כתובת אימייל</label>
              <input
                type="email"
                id="email"
                name="email"
                value={this.state.email}
                readOnly
              />
              <label htmlFor="role">הרשאות</label>
              <select
                name="role"
                id="role"
                value={this.state.role}
                onChange={(e) => this.roleChangeHandler(e.target.value)}
              >
                <option value="0">ללא הרשאות</option>
                <option value="1">עורך תוכן</option>
                <option value="2">מנהל מערכת</option>
              </select>
            </form>
            <button
              className="delete"
              onClick={() => this.deleteUser()}
              disabled={!this.state.canClickDelete}
            >
              {this.state.canClickDelete ? "מוחק" : "מחק משתמש..."}
            </button>
            <button
              className="save"
              onClick={() => this.updateUser()}
              disabled={!this.state.canClick}
            >
              {this.state.canClick ? "שמירה" : "שומר..."}
            </button>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default User;
