import React, { Component } from "react";
import classes from "./users.module.scss";

import { getAllUsers } from "../../../commons/Database";

import User from "./User/user";

class Users extends Component {
  state = { users: [], noResults: false };

  componentDidMount() {
    getAllUsers().then((users) => {
      this.setState({ users });
      if (users.length === 0) {
        this.setState({ noResults: true });
      }
    });
  }

  render() {
    return (
      <div className={classes.usersList}>
        {this.state.users.length !== 0 ? (
          this.state.users.map((user) => {
            return (
              <User
                key={user._id}
                id={user._id}
                name={user.name}
                email={user.email}
                role={user.role}
              />
            );
          })
        ) : this.state.noResults ? (
          <div className="nores">אין משתמשים</div>
        ) : (
          <div className="loading">טוען את המשתמשים...</div>
        )}
      </div>
    );
  }
}

export default Users;
