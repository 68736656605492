import React from "react";
import classes from "./locationItem.module.scss";

const months = {
  1: "ינואר",
  2: "פברואר",
  3: "מרץ",
  4: "אפריל",
  5: "מאי",
  6: "יוני",
  7: "יולי",
  8: "אוגוסט",
  9: "ספטמבר",
  10: "נובמבר",
  11: "אוקטובר",
  12: "דצמבר",
};

const days = {
  1: "ראשון",
  2: "שני",
  3: "שלישי",
  4: "רביעי",
  5: "חמישי",
  6: "שישי",
  7: "שבת",
};

const dateFormate = (date) => {
  let expires = new Date(date);
  let expiresString = `יום ${
    days[expires.getDay() + 1]
  }, ${expires.getDate()} ב${
    months[expires.getMonth() + 1]
  } ${expires.getFullYear()}, בשעה ${subZero(expires.getHours())}:${subZero(
    expires.getMinutes()
  )}`;
  return expiresString;
};

const subZero = (value) =>
  value.toString().length === 1 ? `0${value}` : value;
const LocationItem = (props) => {
  let tempContent = props.content;
  if (tempContent.split(" ").length >= 20) {
    tempContent = tempContent.split(" ");
    tempContent = tempContent.splice(0, 15).join(" ") + "...";
  }

  let previewImage = "";
  if (props.type === "pin") {
    previewImage = `https://atlas.microsoft.com/map/static/png?subscription-key=TmHWz6qFa5lKRU34C-V2iOXtaiw1bhTubtsUmUKphjs&api-version=1.0&style=dark&zoom=10&center=${props.coordinates[1]}, ${props.coordinates[0]}&pins=custom||${props.coordinates[1]} ${props.coordinates[0]}||https://maps-api.azurewebsites.net/assets/target.png&width=1000&height=500`;
  } else {
    let pathsString = props.coordinates.map((coordinate) => {
      return coordinate.join(" ");
    });

    let maxLng = undefined;

    props.coordinates.forEach((item) => {
      if (typeof maxLng === "undefined") {
        maxLng = item[0];
      } else if (item[0] > maxLng) {
        maxLng = item[0];
      }
    });

    let maxLat = undefined;

    props.coordinates.forEach((item) => {
      if (typeof maxLat === "undefined") {
        maxLat = item[1];
      } else if (item[1] > maxLat) {
        maxLat = item[1];
      }
    });
    let minLng = undefined;

    props.coordinates.forEach((item) => {
      if (typeof minLng === "undefined") {
        minLng = item[0];
      } else if (item[0] < minLng) {
        minLng = item[0];
      }
    });
    let minLat = undefined;

    props.coordinates.forEach((item) => {
      if (typeof minLat === "undefined") {
        minLat = item[1];
      } else if (item[1] < minLat) {
        minLat = item[1];
      }
    });

    const bbox = `${minLng}, ${minLat}, ${maxLng}, ${maxLat}`;
    let centerLat = (minLat + maxLat) / 2;
    let centerLng = (minLng + maxLng) / 2;
    const centerOfPath = `${centerLng}, ${centerLat}`;

    previewImage = `https://atlas.microsoft.com/map/static/png?subscription-key=TmHWz6qFa5lKRU34C-V2iOXtaiw1bhTubtsUmUKphjs&api-version=1.0&style=dark&zoom=10&path=lcFFFFFF|lw15||${pathsString.join(
      "|"
    )}&center=${centerOfPath}&width=1000`;
  }

  let expires = dateFormate(props.expires);
  return (
    <div
      className={classes.item}
      onClick={() => {
        window.location.href = `/manage/locations/edit/${props.id}`;
      }}
    >
      <img src={previewImage} />
      <div className={classes.details}>
        <div className={classes.title}>{props.title}</div>
        <div className={classes.content}>
          {tempContent.split("\n").map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
        <div className={classes.expires}>{expires}</div>
      </div>
    </div>
  );
};

export default LocationItem;
