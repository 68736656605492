import React, { Component, useEffect } from "react";
import Locations from "../../commons/ListOfLocations";
import {
  map,
  datasource,
  lineInfoDatasource,
  linesource,
  GetMap,
  setWindowOpenCallback,
} from "../../helpers/mapLoader";
import Header from "../navbar/navbar";
import { getAllLocations } from "../../commons/Database";
import classes from "./home.module.scss";
import ErrorBoundry from "../../helpers/ErrorBoundry/errorBoundry";
import Window from "./window/window";

class Map extends Component {
  state = {
    showWindow: false,
    windowTitle: "",
    windowContent: "",
    windowImageSrc: "",
  };

  larPin = [];

  getLocations = () => {
    getAllLocations().then((points) => {
      points.forEach((point, i) => {
        if (point.properties.locationType == "pin") {
          let image = "";

          if (
            "image" in point.properties &&
            typeof point.properties.image.url !== "undefined"
          ) {
            image = point.properties.image.url;
          }

          this.lastPin = [
            point.geometry.coordinates[1],
            point.geometry.coordinates[0],
          ];

          datasource.add(
            new window.atlas.data.Feature(
              new window.atlas.data.Point([
                point.geometry.coordinates[1],
                point.geometry.coordinates[0],
              ]),
              {
                title: point.properties.title,
                content: point.properties.content,
                image,
              }
            )
          );
        } else if (point.properties.locationType == "path") {
          if (typeof linesource != "undefined") {
            let image = "";

            this.lastPin =
              point.geometry.coordinates[point.geometry.coordinates.length - 1];
            if (
              "image" in point.properties &&
              typeof point.properties.image.url !== "undefined"
            ) {
              image = point.properties.image.url;
            }

            linesource.add(
              new window.atlas.data.Feature(
                new window.atlas.data.LineString(point.geometry.coordinates)
              )
            );
            lineInfoDatasource.add(
              new window.atlas.data.Feature(
                new window.atlas.data.Point(
                  point.geometry.coordinates[
                    Math.floor(point.geometry.coordinates.length / 2)
                  ]
                ),
                {
                  title: point.properties.title,
                  content: point.properties.content,
                  image,
                }
              )
            );
          }
        }
      });

      if (points.length > 0) {
        setTimeout(() => {
          map.setCamera({
            center: this.lastPin,
            zoom: 9,
            type: "ease",
            duration: 400,
          });
        }, 1500);
      }
    });
  };

  goTo = (where) => {
    let center = Locations[where].location;
    let zoom = Locations[where].zoom;
    map.setCamera({
      center,
      zoom,
      duration: 500,
      type: "ease",
    });
  };

  openWindow = (properties) => {
    this.setState({ windowTitle: properties.title });
    this.setState({ windowContent: properties.content });
    this.setState({ windowImageSrc: properties.image });
    this.setState({ showWindow: true });
  };

  initMap = () => {
    GetMap().then(() => {
      this.getLocations();
      this.addHandlerAndPoint();
      setWindowOpenCallback(this.openWindow);
    });
  };

  addHandlerAndPoint = () => {
    map.events.add("click", (e) => {
      // this.closeInfoWindow(e);
    });
  };

  closeInfoWindow = () => {
    this.setState({ showWindow: false });
  };

  componentDidMount() {
    this.initMap();
  }

  render() {
    let showWindow = null;

    if (this.state.showWindow) {
      showWindow = (
        <div
          className={classes.windowBg}
          onClick={() => this.closeInfoWindow()}
        >
          <Window
            windowImageSrc={this.state.windowImageSrc}
            windowTitle={this.state.windowTitle}
            windowContent={this.state.windowContent}
            closeInfoWindow={this.closeInfoWindow}
          />
        </div>
      );
    }

    return (
      <ErrorBoundry>
        {showWindow}
        <div style={{ height: "100vh" }} className={classes.body}>
          <Header click={this.goTo} lastEvent={this.lastPin} />
          <div
            id="map"
            style={{
              position: "relative",
              width: "100%",
              minWidth: "290px",
              height: "100vh",
            }}
          ></div>
        </div>
      </ErrorBoundry>
    );
  }
}

export default Map;
