import axios from "axios";
import Urls from "./Urls";
import { reduxStore } from "../index.js";
var store = require("store");

let currentUser = "";

const login = (email: String, password: String) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${Urls}/users/login`,
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((resp) => resp.data)
      .then((data) => {
        store.set("token", data.token);

        resolve(true);
      })
      .catch((err) => resolve(false));
  });
};
const register = (name: String, email: String, password: String) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${Urls}/users/register`,
        { name, email, password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((resp) => resp.data)
      .then((data) => {
        store.set("token", data.token);
        resolve(true);
      })
      .catch((err) => resolve(false));
  });
};

const getUser = () => {
  return new Promise((resolve) => {
    if (currentUser === "") {
      if (typeof store.get("token") === "undefined") {
        resolve(undefined);
      } else {
        axios
          .get(`${Urls}/users/me`, {
            headers: { Authorization: `Bearer ${store.get("token")}` },
          })
          .then((resp) => resp.data)
          .then((data) => {
            if ("_id" in data) {
              resolve(data);
            } else {
              resolve(undefined);
            }
          })
          .catch((err) => resolve(undefined));
      }
    } else {
      resolve(currentUser);
    }
  });
};

const isLogged = () => {
  return new Promise((resolve) => {
    if (typeof store.get("token") === "undefined") {
      resolve(false);
    } else {
      axios
        .get(`${Urls}/users/me`, {
          headers: { Authorization: `Bearer ${store.get("token")}` },
        })
        .then((resp) => resp.data)
        .then((data) => {
          if ("_id" in data) {
            currentUser = data;
            reduxStore.dispatch({
              type: "CHANGE_LOGGED_STATUS",
              payload: true,
            });
            reduxStore.dispatch({
              type: "CHANGE_USER",
              payload: data,
            });
            resolve(true);
          } else {
            reduxStore.dispatch({
              type: "CHANGE_LOGGED_STATUS",
              payload: false,
            });
            reduxStore.dispatch({
              type: "CHANGE_USER",
              payload: null,
            });
            resolve(false);
          }
        })
        .catch((err) => resolve(false));
    }
  });
};

const canAccess = (permission = 1) => {
  return new Promise((resolve) => {
    if (typeof store.get("token") === "undefined") {
      resolve(false);
    } else {
      axios
        .get(`${Urls}/users/me`, {
          headers: { Authorization: `Bearer ${store.get("token")}` },
        })
        .then((resp) => resp.data)
        .then((data) => {
          if ("role" in data) {
            if (data.role >= permission) {
              currentUser = data;

              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        })
        .catch((err) => resolve(false));
    }
  });
};

const logout = () => {
  return new Promise((resolve) => {
    axios
      .post(
        `${Urls}/users/logout`,
        {},
        { headers: { Authorization: `Bearer ${store.get("token")}` } }
      )
      .then((resp) => resp.data)
      .then((data) => {
        if ("_id" in data) {
          currentUser = data;
          store.remove("token");
          reduxStore.dispatch({
            type: "CHANGE_LOGGED_STATUS",
            payload: false,
          });
          reduxStore.dispatch({
            type: "CHANGE_USER",
            payload: null,
          });
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((err) => resolve(false));
  });
};

const Auth = { login, register, logout, isLogged, canAccess, getUser };

export default Auth;
