const todaysDate = () => 
new Date().getFullYear() + "-" + subZero(new Date().getMonth() + 1) + "-" + subZero(new Date().getDate());

const tomorowsDate = () =>{
    const today = new Date();
    const tempDate = new Date(today);
    tempDate.setDate(tempDate.getDate() + 1);
    return tempDate.getFullYear() + "-" + subZero(tempDate.getMonth() + 1) + "-" + subZero(tempDate.getDate() + 1);
}

const nextWeeksDate = () =>{
    const today = new Date();
    const tempDate = new Date(today);
    tempDate.setDate(tempDate.getDate() + 7)
    return tempDate.getFullYear() + "-" + subZero(tempDate.getMonth() + 1) + "-" + subZero(tempDate.getDate());
}

const timeRightNow = () =>{
return subZero(new Date().getHours()) + ':' + subZero(new Date().getMinutes());
}

const dates = {
today: todaysDate,
tomorow: tomorowsDate,
nextWeek: nextWeeksDate,
time: timeRightNow
}

const subZero = (value) => value.toString().length == 1 ? `0${value}` : value;



export default dates;