import React, { Component } from "react";
import Locations from "../../../../commons/ListOfLocations";
import {
  map,
  linesource,
  datasource,
  GetMap,
} from "../../../../helpers/mapLoader";
import classes from "./addLocation.module.scss";
import NavBar from "../../../navbar/navbar";
import Header from "../../../../commons/Header/header";
import Dates from "../../../../helpers/dates";
import {
  addLocation,
  addPath,
  imageUpload,
  uploadPreview,
} from "../../../../commons/Database";

import Form from "../Form/form";
import Navigation from "../Navigation/navigation";

class AddLocation extends Component {
  state = {
    lat: Locations.Israel.location[1],
    lng: Locations.Israel.location[0],
    previewImage: "",
    selectedFile: "",
    name: "",
    content: "",
    loading: false,
    loadingStatus: "",
    pathLocations: [],
    path: false,
    date: Dates.nextWeek(),
    time: Dates.time(),
  };
  point = "";

  componentDidMount() {
    this.initMap();
    document.addEventListener("keydown", this.keydownHandler);
  }

  initMap = async () => {
    await GetMap("satellite_road_labels");
    this.addHandlerAndPoint();
  };

  addHandlerAndPoint = () => {
    map.events.add("click", (e) => {
      this.mapClickHandler(e);
    });
    this.updatePointLocation();
  };

  updatePointLocation = () => {
    datasource.clear();
    let previewImage = "";
    if (
      this.state.previewImage != "" &&
      typeof this.state.previewImage != "undefined"
    ) {
      previewImage = this.state.previewImage;
    }
    datasource.add(
      new window.atlas.data.Feature(
        new window.atlas.data.Point([this.state.lng, this.state.lat]),
        {
          title: this.state.name,
          content: this.state.content,
          image: previewImage,
        }
      )
    );
  };

  mapClickHandler = (e) => {
    if (e) {
      if (this.state.path) {
        //Add a layer for rendering the route lines and have it render under the map labels.
        let locations = [...this.state.pathLocations];
        locations.push(e.position);
        this.setState({ pathLocations: locations }, () => {
          linesource.clear();
          linesource.add(
            new window.atlas.data.Feature(
              new window.atlas.data.LineString(this.state.pathLocations)
            ),
            {
              title: this.state.name,
              content: this.state.content,
              image: "",
            }
          );
        });
      } else {
        if (typeof e != "undefined") {
          this.setState({ lat: e.position[1] }, () => {
            this.setState({ lng: e.position[0] }, () => {
              this.updatePointLocation();
            });
          });
        }
      }
    }
  };

  goTo = (where) => {
    let center = Locations[where].location;
    let zoom = Locations[where].zoom;
    map.setCamera({
      center,
      zoom,
      duration: 500,
      type: "ease",
    });
  };

  showImage = (input) => {
    this.setState({ selectedFile: input.value });

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.setState({ previewImage: e.target.result });
        this.updatePointLocation();
      };

      reader.readAsDataURL(input.files[0]);
    }
  };

  dateChangedHandler = (date) => {
    this.setState({ date });
  };
  timeChangedHandler = (time) => {
    this.setState({ time });
  };

  latChangedHandler = (newValue) => {
    this.setState({ lat: newValue }, () => {
      this.updatePointLocation();
    });
  };

  lngChangedHandler = (newValue) => {
    this.setState({ lng: newValue }, () => {
      this.updatePointLocation();
    });
  };
  nameChangedHandler = (newValue) => {
    this.setState({ name: newValue }, () => {
      this.updatePointLocation();
    });
  };
  contentChangedHandler = (newValue) => {
    this.setState({ content: newValue }, () => {
      this.updatePointLocation();
    });
  };

  removeImage = () => {
    this.setState({ previewImage: "" }, () => this.updatePointLocation());
    this.setState({ selectedFile: "" });
  };

  saveLocation = async (e) => {
    this.setState({ loadingStatus: "שומר את המיקום" });
    this.setState({ loading: true });
    e.preventDefault();
    let locationDocument = null;
    if (this.state.path) {
      locationDocument = await addPath({
        properties: {
          title: this.state.name,
          content: this.state.content,
          expiresAt: new Date(`${this.state.date} ${this.state.time}`),
        },
        geometry: {
          coordinates: [...this.state.pathLocations],
        },
      });
    } else {
      locationDocument = await addLocation({
        properties: {
          title: this.state.name,
          content: this.state.content,
          expiresAt: new Date(`${this.state.date} ${this.state.time}`),
        },
        geometry: {
          coordinates: `${this.state.lat},${this.state.lng}`,
        },
      });
    }
    if (this.state.selectedFile == "") {
      this.setState({ loadingStatus: "המיקום נשמר בהצלחה!" });
      setTimeout(() => {
        window.location.href = "/manage";
      }, 1000);
      return;
    }
    this.setState({ loadingStatus: "מעלה את התמונה" });

    const file = document.querySelector("input[type=file]");
    const singleFile = file.files[0];

    const imageDocument = await imageUpload(
      singleFile,
      locationDocument._id,
      this.state.path
    );

    this.setState({ loadingStatus: "המיקום נשמר בהצלחה!" });
    setTimeout(() => {
      window.location.href = "/manage";
    }, 1000);
  };

  togglePath = (boolean) => {
    const tempPath = this.state.path;
    if (tempPath === boolean) {
      this.setState({ path: !tempPath }, () => {
        if (this.state.path) {
          datasource.clear();
          linesource.add(
            new window.atlas.data.LineString(this.state.pathLocations)
          );
        } else {
          this.updatePointLocation();
          linesource.clear();
        }
      });
      this.mapClickHandler();
    }
  };

  clearPath = () => {
    linesource.clear();
    this.setState({ pathLocations: [] });
  };

  undoPath = () => {
    linesource.clear();
    let tempLocations = [...this.state.pathLocations];
    tempLocations.pop();
    this.setState({ pathLocations: tempLocations }, () => {
      linesource.add(
        new window.atlas.data.Feature(
          new window.atlas.data.LineString(this.state.pathLocations)
        ),
        {
          title: this.state.name,
          content: this.state.content,
          image: "",
        }
      );
    });
  };

  keydownHandler = (e) => {
    if (document.activeElement.tagName === "CANVAS") {
      if ((e.keyCode == 90 && e.ctrlKey) || (e.keyCode == 90 && e.metaKey)) {
        this.undoPath();
      }
    }
  };

  render() {
    let image = null;

    if (this.state.previewImage !== "") {
      image = (
        <React.Fragment>
          <img src={this.state.previewImage} />
          <button onClick={() => this.removeImage()} className={classes.delete}>
            מחיקת תמונה
          </button>
        </React.Fragment>
      );
    }

    let loading = null;

    if (this.state.loading) {
      loading = (
        <div className={classes.loading}>
          <div className={classes.loadingText}>{this.state.loadingStatus}</div>
        </div>
      );
    }

    let clearButton = null;

    if (this.state.path) {
      clearButton = (
        <React.Fragment>
          <button
            onClick={() => this.clearPath()}
            className={classes.clearButton}
          >
            נקה מסלול
          </button>
          <button
            onClick={() => this.undoPath()}
            className={classes.undoButton}
          >
            ביטול
          </button>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {loading}
        <Navigation path={this.state.path} togglePath={this.togglePath} />
        <div className={classes.content} style={{ height: "100vh" }}>
          <div className={classes.right}>
            <Form
              path={this.state.path}
              lat={this.state.lat}
              lng={this.state.lng}
              name={this.state.name}
              content={this.state.content}
              selectedFile={this.state.selectedFile}
              image={image}
              latChangedHandler={this.latChangedHandler}
              lngChangedHandler={this.lngChangedHandler}
              dateChangedHandler={this.dateChangedHandler}
              timeChangedHandler={this.timeChangedHandler}
              nameChangedHandler={this.nameChangedHandler}
              contentChangedHandler={this.contentChangedHandler}
              showImage={this.showImage}
              saveLocation={this.saveLocation}
              date={this.state.date}
              time={this.state.time}
            />
          </div>
          <div className={classes.left}>
            {clearButton}
            <NavBar click={this.goTo} />
            <div
              id="map"
              style={{
                position: "relative",
                width: "100%",
                minWidth: "290px",
                height: "100vh",
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddLocation;
