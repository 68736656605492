import React, { Component } from "react";
import Locations from "../../../../commons/ListOfLocations";
import {
  map,
  linesource,
  datasource,
  GetMap,
} from "../../../../helpers/mapLoader";
import classes from "./editLocation.module.scss";
import NavBar from "../../../navbar/navbar";
import Dates from "../../../../helpers/dates";
import {
  updateLocation,
  deleteLocation,
  imageUpload,
  getLocation,
} from "../../../../commons/Database";

import Form from "../Form/form";
import Navigation from "../Navigation/navigation";
import Loading from "../../../../commons/Loading/loading";

import { showConfirm } from "../../../../helpers/alert";

class EditLocation extends Component {
  state = {
    lat: Locations.Israel.location[1],
    lng: Locations.Israel.location[0],
    previewImage: "",
    selectedFile: "",
    name: "",
    content: "",
    loading: true,
    loadingStatus: "משיג מידע על המיקום...",
    locationId: "",
    pathLocations: [],
    path: false,
    date: Dates.nextWeek(),
    time: Dates.time(),
  };
  point = "";

  componentDidMount() {
    document.addEventListener("keydown", this.keydownHandler);

    this.setState({ locationId: this.props.match.params.id }, async () => {
      const location = await getLocation(this.state.locationId);
      const properties = location.properties;
      const coordinates = location.geometry.coordinates;
      if (properties.locationType === "pin") {
        this.setState({ lat: coordinates[0] });
        this.setState({ lng: coordinates[1] });
        this.setState({ path: false });
      } else {
        this.setState({ pathLocations: coordinates });
        this.setState({ path: true });
      }
      this.setState({ loading: false }, () => {
        this.initMap();
      });
      this.setLocation(properties);
    });
  }

  setLocation = (properties) => {
    if ("title" in properties) {
      this.setState({ name: properties.title });
    }
    if ("content" in properties) {
      this.setState({ content: properties.content });
    }
    if ("image" in properties) {
      this.setState({ previewImage: properties.image.url });
    }
  };

  initMap = async () => {
    await GetMap("satellite_road_labels");
    this.addHandlerAndPoint();
    this.togglePath(this.state.path);
  };

  addHandlerAndPoint = () => {
    map.events.add("click", (e) => {
      this.mapClickHandler(e);
    });
    this.updatePointLocation();
  };

  updatePointLocation = () => {
    datasource.clear();
    let previewImage = "";
    if (
      this.state.previewImage != "" &&
      typeof this.state.previewImage != "undefined"
    ) {
      previewImage = this.state.previewImage;
    }
    datasource.add(
      new window.atlas.data.Feature(
        new window.atlas.data.Point([this.state.lng, this.state.lat]),
        {
          title: this.state.name,
          content: this.state.content,
          image: previewImage,
        }
      )
    );
  };

  mapClickHandler = (e) => {
    if (e) {
      if (this.state.path) {
        //Add a layer for rendering the route lines and have it render under the map labels.
        let locations = [...this.state.pathLocations];
        locations.push(e.position);
        this.setState({ pathLocations: locations }, () => {
          linesource.clear();
          linesource.add(
            new window.atlas.data.Feature(
              new window.atlas.data.LineString(this.state.pathLocations)
            ),
            {
              title: this.state.name,
              content: this.state.content,
              image: "",
            }
          );
        });
      } else {
        if (typeof e != "undefined") {
          this.setState({ lat: e.position[1] }, () => {
            this.setState({ lng: e.position[0] }, () => {
              this.updatePointLocation();
            });
          });
        }
      }
    }
  };

  goTo = (where) => {
    let center = Locations[where].location;
    let zoom = Locations[where].zoom;
    map.setCamera({
      center,
      zoom,
      duration: 500,
      type: "ease",
    });
  };

  deleteLocationHandler = async (e) => {
    e.preventDefault();
    if (await showConfirm("זוהי פעולה שלא ניתן לשחזר", { title: "בטוח?" })) {
      if (await showConfirm("הזדמנות אחרונה")) {
        this.setState({ loading: true });
        await deleteLocation(this.state.locationId);
        window.location.replace("/manage");
      }
    }
  };

  showImage = (input) => {
    this.setState({ selectedFile: input.value });

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.setState({ previewImage: e.target.result });
        this.updatePointLocation();
      };

      reader.readAsDataURL(input.files[0]);
    }
  };

  latChangedHandler = (newValue) => {
    this.setState({ lat: newValue }, () => {
      this.updatePointLocation();
    });
  };

  lngChangedHandler = (newValue) => {
    this.setState({ lng: newValue }, () => {
      this.updatePointLocation();
    });
  };

  dateChangedHandler = (date) => {
    this.setState({ date });
  };
  timeChangedHandler = (time) => {
    this.setState({ time });
  };
  nameChangedHandler = (newValue) => {
    this.setState({ name: newValue }, () => {
      this.updatePointLocation();
    });
  };
  contentChangedHandler = (newValue) => {
    this.setState({ content: newValue }, () => {
      this.updatePointLocation();
    });
  };

  removeImage = () => {
    this.setState({ previewImage: "" }, () => this.updatePointLocation());
    this.setState({ selectedFile: "" });
  };

  saveLocation = async (e) => {
    this.setState({ loadingStatus: "שומר את המיקום" });
    this.setState({ loading: true });
    e.preventDefault();
    let locationDocument = null;
    if (this.state.path) {
      locationDocument = await updateLocation(this.state.locationId, {
        properties: {
          title: this.state.name,
          content: this.state.content,
          expiresAt: new Date(`${this.state.date} ${this.state.time}`),
        },
        geometry: {
          coordinates: [...this.state.pathLocations],
        },
      });
    } else {
      locationDocument = await updateLocation(this.state.locationId, {
        properties: {
          title: this.state.name,
          content: this.state.content,
          expiresAt: new Date(`${this.state.date} ${this.state.time}`),
        },
        geometry: {
          coordinates: [this.state.lat, this.state.lng],
        },
      });
    }
    if (this.state.selectedFile == "") {
      this.setState({ loadingStatus: "המיקום נשמר בהצלחה!" });
      setTimeout(() => {
        window.location.href = "/manage";
      }, 1000);
      return;
    }
    this.setState({ loadingStatus: "מעלה את התמונה" });

    const file = document.querySelector("input[type=file]");
    const singleFile = file.files[0];

    const imageDocument = await imageUpload(
      singleFile,
      locationDocument._id,
      this.state.path
    );

    this.setState({ loadingStatus: "המיקום נשמר בהצלחה!" });
    setTimeout(() => {
      window.location.href = "/manage";
    }, 1000);
  };

  togglePath = (boolean) => {
    this.setState({ path: boolean }, () => {
      if (boolean) {
        datasource.clear();
        linesource.add(
          new window.atlas.data.LineString(this.state.pathLocations)
        );
      } else {
        this.updatePointLocation();
        linesource.clear();
      }
    });
    this.mapClickHandler();
  };

  clearPath = () => {
    linesource.clear();
    this.setState({ pathLocations: [] });
  };

  undoPath = () => {
    linesource.clear();
    let tempLocations = [...this.state.pathLocations];
    tempLocations.pop();
    this.setState({ pathLocations: tempLocations }, () => {
      linesource.add(
        new window.atlas.data.Feature(
          new window.atlas.data.LineString(this.state.pathLocations)
        ),
        {
          title: this.state.name,
          content: this.state.content,
          image: "",
        }
      );
    });
  };

  keydownHandler = (e) => {
    if (document.activeElement.tagName === "CANVAS") {
      if ((e.keyCode == 90 && e.ctrlKey) || (e.keyCode == 90 && e.metaKey)) {
        this.undoPath();
      }
    }
  };

  render() {
    let image = null;

    if (this.state.previewImage !== "") {
      image = (
        <React.Fragment>
          <img src={this.state.previewImage} />
          <button onClick={() => this.removeImage()} className={classes.delete}>
            מחיקת תמונה
          </button>
        </React.Fragment>
      );
    }

    let clearButton = null;

    if (this.state.path) {
      clearButton = (
        <React.Fragment>
          <button
            onClick={() => this.clearPath()}
            className={classes.clearButton}
          >
            נקה מסלול
          </button>
          <button
            onClick={() => this.undoPath()}
            className={classes.undoButton}
          >
            ביטול
          </button>
        </React.Fragment>
      );
    }
    return (
      <Loading
        loading={this.state.loading}
        loadingText={this.state.loadingStatus}
        renderChildrenAnyway
      >
        <Navigation path={this.state.path} togglePath={this.togglePath} />
        <div className={classes.content} style={{ height: "100vh" }}>
          <div className={classes.right}>
            <Form
              path={this.state.path}
              lat={this.state.lat}
              lng={this.state.lng}
              name={this.state.name}
              content={this.state.content}
              selectedFile={this.state.selectedFile}
              image={image}
              latChangedHandler={this.latChangedHandler}
              lngChangedHandler={this.lngChangedHandler}
              nameChangedHandler={this.nameChangedHandler}
              contentChangedHandler={this.contentChangedHandler}
              showImage={this.showImage}
              saveLocation={this.saveLocation}
              delete={true}
              deleteLocationHandler={this.deleteLocationHandler}
              date={this.state.date}
              time={this.state.time}
              dateChangedHandler={this.dateChangedHandler}
              timeChangedHandler={this.timeChangedHandler}
            />
          </div>
          <div className={classes.left}>
            {clearButton}
            <NavBar click={this.goTo} />
            <div
              id="map"
              style={{
                position: "relative",
                width: "100%",
                minWidth: "290px",
                height: "100vh",
              }}
            />
          </div>
        </div>
      </Loading>
    );
  }
}

export default EditLocation;
