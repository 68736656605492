import Locations from "../commons/ListOfLocations";

export let map,
  linesource,
  datasource,
  lineInfoDatasource,
  bubbleLayer,
  lineLayer,
  popup = null;
let callback = () => {};
//A duration for the animation in ms.
var duration = 2500;

//Max radius of the pulse circle.
var maxRadius = 30;

export async function GetMap(
  style = "grayscale_dark",
  mapIndicator = "map",
  scroll = true
) {
  return new Promise((resolve) => {
    //Initialize a map instance.
    map = new window.atlas.Map(mapIndicator, {
      center: Locations.Israel.location,
      zoom: 6,
      // style: 'grayscale_dark',
      style,
      view: "Auto",
      showLogo: false,
      boxZoomInteraction: scroll,
      dblClickZoomInteraction: scroll,
      dragPanInteraction: scroll,
      dragRotateInteraction: scroll,
      interactive: scroll,
      keyboardInteraction: scroll,
      scrollZoomInteraction: scroll,
      touchInteraction: scroll,
      language: "en-US",

      //Add your Azure Maps key to the map SDK. Get an Azure Maps key at https://azure.com/maps. NOTE: The primary key should be used as the key.
      authOptions: {
        authType: "subscriptionKey",
        subscriptionKey: "TmHWz6qFa5lKRU34C-V2iOXtaiw1bhTubtsUmUKphjs",
      },
    });

    //Wait until the map resources are ready.
    map.events.add("ready", async function () {
      //Create a data source to add your data to.
      datasource = new window.atlas.source.DataSource();
      map.sources.add(datasource);

      //Create a data source to add your data to.
      lineInfoDatasource = new window.atlas.source.DataSource();
      map.sources.add(lineInfoDatasource);

      //Create a data source to add your data to.
      linesource = new window.atlas.source.DataSource();
      map.sources.add(linesource);

      await map.imageSprite.add(
        "target",
        "https://maps-api.azurewebsites.net/assets/target.svg"
      );
      await map.imageSprite.add(
        "info",
        "https://maps-api.azurewebsites.net/assets/info.svg"
      );
      await map.imageSprite.add(
        "arrow-icon",
        "https://maps-api.azurewebsites.net/assets/arrow.svg"
      );

      //A bubble layer that will have its radius scaled during animation to create a pulse.
      bubbleLayer = new window.atlas.layer.BubbleLayer(datasource, null, {
        color: "rgb(152, 2, 2)",

        //Hide the stroke of the bubble.
        strokeWidth: 0,

        //Make bubbles stay flat on the map when the map is pitched.
        pitchAlignment: "map",
      });

      let lineInfoIcon = new window.atlas.layer.SymbolLayer(
        lineInfoDatasource,
        null,
        {
          iconOptions: {
            //Pass in the id of the custom icon that was loaded into the map resources.
            ignorePlacement: true,
            image: "info",
            anchor: "center",
            //Optionally scale the size of the icon.
            size: 0.1,
          },
          textOptions: {
            //For smoother animation, ignore the placement of the text. This skips the label collision calculations and allows the text to overlap map labels.
            ignorePlacement: true,

            //For smoother animation, allow text to overlap all other symbols on the map.
            allowOverlap: true,
          },
        }
      );

      //Create a line layer to render the line to the map.
      lineLayer = new window.atlas.layer.LineLayer(linesource, null, {
        strokeColor: "#fff",
        strokeWidth: 4,
        pitchAlignment: "map",
      });

      //Add a layers for rendering data.
      map.layers.add([
        bubbleLayer,
        lineLayer,
        //A symbol layer to be the main icon layer for the data point.
        new window.atlas.layer.SymbolLayer(datasource, null, {
          iconOptions: {
            //Pass in the id of the custom icon that was loaded into the map resources.
            ignorePlacement: true,
            image: "target",
            anchor: "center",
            //Optionally scale the size of the icon.
            size: 0.2,
          },
          textOptions: {
            //For smoother animation, ignore the placement of the text. This skips the label collision calculations and allows the text to overlap map labels.
            ignorePlacement: true,

            //For smoother animation, allow text to overlap all other symbols on the map.
            allowOverlap: true,
          },
        }),
        lineInfoIcon,
      ]);

      animate(0);

      // map.events.add('click', datasource, showPopup);

      //Create a popup but leave it closed so we can update it and display it later.
      popup = new window.atlas.Popup({
        closeButton: false,
      });

      //Add a mouse over event to the result layer and display a popup when this event fires.
      if (window.screen.width >= 900) {
        map.events.add("mouseover", bubbleLayer, showPopup);
        map.events.add("mouseleave", bubbleLayer, () => popup.close());
      }
      map.events.add("click", bubbleLayer, openWindow);

      if (window.screen.width >= 900) {
        map.events.add("mouseover", lineInfoIcon, showPopup);
        map.events.add("mouseleave", lineInfoIcon, () => popup.close());
      }
      map.events.add("click", lineInfoIcon, openWindow);
      resolve("done");
    });
  });
}

function showPopup(e) {
  //Get the properties and coordinates of the first shape that the event occurred on.
  var p = e.shapes[0].getProperties();
  var position = e.position;

  let content = p.content;
  if (typeof content != "undefined") {
    if (content.split(" ").length >= 30) {
      content =
        content.split(" ").slice(0, 30).join(" ") +
        "\n\nלמידע נוסף לחצו על אייקון המטרה.";
    }
    content = content.replace(/\n/g, "<br />");
  } else {
    content = "";
  }
  //Create HTML from properties of the selected result.
  var html = `
    ${
      typeof p.image != "undefined" && p.image != ""
        ? `<img src="${p.image}" style="min-width: 100%; max-width: 200px">`
        : ""
    }
        
      <div style="padding:5px; max-width: 400px;">
        <div style="font-size: 16px; overflow: hidden; text-overflow: ellipsis; width: 100%;"><b>${
          p.title
        }</b></div>
        <div style="width: 100%; overflow: hidden; text-overflow: ellipsis;">${content}</div>
      </div>`;

  //Update the content and position of the popup.
  popup.setPopupOptions({
    content: html,
    position: position,
  });

  //Open the popup.
  popup.open(map);
}

function openWindow(e) {
  var p = e.shapes[0].getProperties();
  callback(p);
}

export function setWindowOpenCallback(callbackFunction) {
  callback = callbackFunction;
}

function animate(timestamp) {
  //Calculate progress as a ratio of the duration between 0 and 1.
  window.progress = (timestamp % duration) / duration;

  //Early in the animaiton, make the radius small but don't render it. The map transitions between radiis, which causes a flash when going from large radius to small radius. This resolves that.
  if (window.progress < 0.1) {
    bubbleLayer.setOptions({
      radius: 0,
      opacity: 0,
    });
  } else {
    bubbleLayer.setOptions({
      radius: maxRadius * window.progress,

      //Have the opacity fade as the radius becomes larger.
      opacity: Math.max(0.9 - window.progress, 0),
    });
  }

  //Request the next frame of the animation.
  window.animation = requestAnimationFrame(animate);
}

//  const goTo = (where) =>{
//     let center = [];
//     let zoom = 7;
//     if(where === 'gaza'){
//         center = Gaza;
//         zoom = 10;
//     }
//     if(where === 'lebanon'){
//         center = Lebanon;
//         zoom = 8;
//     }
//     if(where === 'syria'){
//         center = Syria;
//     }

//     map.setCamera({
//         center,
//         zoom,
//         duration: 500,
//         type: 'ease'
//     });
// }
