import React from "react";
import classes from "./header.module.scss";
import Auth from "../Auth";

const logout = async (e) => {
  e.preventDefault();
  const logoutResult = await Auth.logout();
  window.location.reload();
};

const Header = (props) => {
  return (
    <div className={classes.header}>
      <div className={classes.accountBox}>
        <div className={classes.circle}>{props.name.substring(0, 1)}</div>
        <div className={classes.name}>{props.name}</div>
        <div className={classes.logOut}>
          <button onClick={(e) => logout(e)}>התנתקות</button>
        </div>
      </div>
      <a href="/manage">
        <img src="/assets/logo/idf/white.svg" />
      </a>
      <a href="/manage">
        <div className={classes.title}>{props.title || "לוח ניהול"}</div>
      </a>
    </div>
  );
};

export default Header;
