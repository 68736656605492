import React from "react";
import classes from "../AddLocation/addLocation.module.scss";

const Navigation = (props) => {
  return (
    <div className={classes.modeToggleContainer}>
      <div
        onClick={() => props.togglePath(true)}
        className={`${classes.option} ${!props.path ? classes.active : ""}`}
      >
        הוספת מיקום
      </div>
      <div
        onClick={() => props.togglePath(false)}
        className={`${classes.option} ${props.path ? classes.active : ""}`}
      >
        הוספת מסלול
      </div>
    </div>
  );
};

export default Navigation;
