import React from "react";
import logo from "./logo.svg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";
import Map from "./components/home/home.js";
import Manage from "./components/manage/manage.js";
import AddLocation from "./components/manage/Locations/AddLocation/addLocation.js";
import EditLocation from "./components/manage/Locations/EditLocation/editLocation";
import Login from "./components/manage/LoginForm/loginForm.js";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Router>
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route exact path="/" component={Map} />
          <Route exact path="/manage" component={Manage} />
          <Route exact path="/manage/locations/add" component={AddLocation} />
          <Route
            exact
            path="/manage/locations/edit/:id"
            component={EditLocation}
          />
          <Route exact path="/manage/login" component={Login} />
        </AnimatedSwitch>
      </Router>
    </div>
  );
}

export default App;
