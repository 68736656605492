import React from "react";
import classes from "./loading.module.css";

const Loading = (props) => {
  return (
    <React.Fragment>
      {props.loading ? (
        <React.Fragment>
          <div className={classes.loadingScreen}>
            <div className={classes.loadingText}>
              {props.loadingText || "טוען..."}
            </div>
          </div>
          {props.renderChildrenAnyway ? props.children : null}
        </React.Fragment>
      ) : (
        props.children
      )}
    </React.Fragment>
  );
};

export default Loading;
