import React, { Component } from 'react';
import classes from './errorBoundry.module.css';

class ErrorBoundry extends Component {
    state = { 
        hasError: false
     };

    componentDidCatch(error, info){
        this.setState({hasError: true});
    }

    render() { 
        let display = this.props.children;
        if(this.state.hasError){
            display = <div className={classes.oops}>
                <h1>אופס!</h1>
                <p>ישנה בעיה לא ידועה, אנא נסו שוב מאוחר יותר</p>
            </div>
        }
        return display ;
    }
}
 
export default ErrorBoundry;
