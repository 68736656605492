import React, { Component } from "react";
import Home from "../../home/home";
import Login from "./Login/login";
import Register from "./Register/register";
import BlurredBg from "../../../helpers/BlurredBg/blurredBg";
import Auth from "../../../commons/Auth";
import Loading from "../../../commons/Loading/loading";
import classes from "./loginForm.module.scss";

class LoginForm extends Component {
  state = {
    email: "",
    password: "",
    loginErrors: "",
    name: "",
    verifyPassword: "",
    buttonIsClickable: true,
    logged: false,
    loading: true,
    isLogin: true,
  };

  registerLinkHandler = () => {
    this.setState({ isLogin: false });
    this.setState({ loginErrors: "" });
  };

  loginLinkHandler = () => {
    this.setState({ isLogin: true });
    this.setState({ loginErrors: "" });
  };

  emailChangedHandler = (email) => {
    this.setState({ email });
  };
  passwordChangedHandler = (password) => {
    this.setState({ password });
  };
  nameChangedHandler = (name) => {
    this.setState({ name });
  };
  verifyPasswordChangedHandler = (verifyPassword) => {
    this.setState({ verifyPassword });
  };
  performLogin = async (e) => {
    e.preventDefault();
    this.setState({ buttonIsClickable: false });
    const loginResult = await Auth.login(this.state.email, this.state.password);
    if (loginResult) {
      window.location.reload();
    } else {
      this.setState({ loginErrors: "שם המשתמש או הסיסמא לא נכונים" });
      this.setState({ buttonIsClickable: true });
    }
  };
  performRegistration = async (e) => {
    e.preventDefault();
    this.setState({ loginErrors: "" });

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.password === "" ||
      this.state.verifyPassword === ""
    ) {
      this.setState({ loginErrors: "אנא מלאו את כל השדות." });
      return;
    }

    if (!this.validateEmail(this.state.email)) {
      this.setState({ loginErrors: "כתובת האימייל אינה חוקית." });
      return;
    }

    if (this.state.password !== this.state.verifyPassword) {
      this.setState({ loginErrors: "הסיסמאות אינן תואמות." });
      return;
    }

    this.setState({ buttonIsClickable: false });

    const success = await Auth.register(
      this.state.name,
      this.state.email,
      this.state.password
    );

    if (success) {
      window.location.reload();
    } else {
      this.setState({ loginErrors: "לא ניתן להירשם. אנא נסו שוב מאוחר יותר." });
      this.setState({ buttonIsClickable: true });
    }
  };

  async componentDidMount() {
    const canAccess = await Auth.canAccess();
    if (canAccess) {
      window.location.replace("/manage");
    }
    const isLogged = await Auth.isLogged();
    this.setState({ logged: isLogged });
    this.setState({ loading: false });
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  logout = async (e) => {
    e.preventDefault();
    const logoutResult = await Auth.logout();
    window.location.reload();
  };
  render() {
    let form = (
      <Login
        register={this.registerLinkHandler}
        emailChanged={this.emailChangedHandler}
        passwordChanged={this.passwordChangedHandler}
        email={this.state.email}
        password={this.state.password}
        loginErrors={this.state.loginErrors}
        buttonIsClickable={this.state.buttonIsClickable}
        login={this.performLogin}
      />
    );

    if (!this.state.isLogin) {
      form = (
        <Register
          register={this.performRegistration}
          emailChanged={this.emailChangedHandler}
          passwordChanged={this.passwordChangedHandler}
          nameChanged={this.nameChangedHandler}
          verifyPasswordChanged={this.verifyPasswordChangedHandler}
          email={this.state.email}
          password={this.state.password}
          name={this.state.name}
          verifyPassword={this.state.verifyPassword}
          loginErrors={this.state.loginErrors}
          buttonIsClickable={this.state.buttonIsClickable}
          login={this.loginLinkHandler}
        />
      );
    }
    return this.state.logged ? (
      <div className={`${classes.center} ${classes["cant-access"]}`}>
        <h1>שלום שגיא</h1>
        <div>
          המשתמש שלך מחובר אך ללא גישה.
          <br />
          אנא בקש גישה ללוח הבקרה ממנהל המערכת.
        </div>
        <button onClick={(e) => this.logout(e)}>לא שגיא? התנתק</button>
      </div>
    ) : (
      <React.Fragment>
        <Loading loading={this.state.loading}>
          <div className={classes.center}>
            {form}
            <div className={"errors"}>{this.state.loginErrors}</div>
          </div>

          <BlurredBg>
            <Home />
          </BlurredBg>
        </Loading>
      </React.Fragment>
    );
  }
}

export default LoginForm;
