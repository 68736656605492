import React from "react";
import classes from "../AddLocation/addLocation.module.scss";

const Form = (props) => {
  let coordinates = null;
  if (!props.path) {
    coordinates = (
      <React.Fragment>
        <div className={classes.sectionTitle}>מיקום</div>
        <label>קורדינטות</label>
        <div className={classes.double}>
          <div className={classes.side}>
            <label htmlFor="lat">קו רוחב (Latitude)</label>
            <input
              type="text"
              id="lat"
              value={props.lat}
              onChange={(e) => props.latChangedHandler(e.target.value)}
            ></input>
          </div>
          <div className={classes.side}>
            <label htmlFor="lng">קו אורך (Longitude)</label>
            <input
              type="text"
              id="lng"
              value={props.lng}
              onChange={(e) => props.lngChangedHandler(e.target.value)}
            ></input>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h1>הוספת מיקום</h1>
      <form>
        {coordinates}
        <div className={classes.sectionTitle}>מידע כללי</div>
        <label htmlFor="name">כותרת</label>
        <input
          type="text"
          id="name"
          value={props.name}
          onChange={(e) => props.nameChangedHandler(e.target.value)}
        ></input>
        <label htmlFor="content">תוכן</label>
        <textarea
          id="content"
          value={props.content}
          onChange={(e) => props.contentChangedHandler(e.target.value)}
        ></textarea>
        <label htmlFor="picture">תמונה</label>
        <input
          type="file"
          id="picture"
          onChange={(e) => props.showImage(e.target)}
          value={props.selectedFile}
        ></input>
        {props.image}
        <div className={classes.sectionTitle}>תוקף</div>
        <label>תאריך תפוגה</label>
        <div className={classes.double}>
          <div className={classes.side}>
            <input
              type="date"
              value={props.date}
              onChange={(e) => props.dateChangedHandler(e.target.value)}
            />
          </div>
          <div className={classes.side}>
            <input
              type="time"
              value={props.time}
              onChange={(e) => props.timeChangedHandler(e.target.value)}
            />
          </div>
        </div>
        <button
          className={classes.saveBtn}
          onClick={(e) => props.saveLocation(e)}
        >
          שמירה
        </button>
        {props.delete ? (
          <button
            className={classes.deleteBtn}
            onClick={(e) => props.deleteLocationHandler(e)}
          >
            מחיקת מיקום
          </button>
        ) : null}
      </form>
    </React.Fragment>
  );
};

export default Form;
