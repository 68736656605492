import React from "react";

import "../Login/login.scss";

const Register = (props) => {
  return (
    <div className="loginFormContainer register">
      <h1>הרשמה</h1>
      <form>
        <label htmlFor="name">שם מלא</label>
        <input
          type="text"
          id="name"
          value={props.name}
          onChange={(e) => props.nameChanged(e.target.value)}
        />
        <label htmlFor="email">כתובת אימייל</label>
        <input
          type="email"
          id="email"
          value={props.email}
          onChange={(e) => props.emailChanged(e.target.value)}
        />
        <label htmlFor="password">סיסמה</label>
        <input
          type="password"
          id="password"
          value={props.password}
          onChange={(e) => props.passwordChanged(e.target.value)}
        />
        <label htmlFor="verifypassword">אימות סיסמה</label>
        <input
          type="password"
          id="verifypassword"
          value={props.verifyPassword}
          onChange={(e) => props.verifyPasswordChanged(e.target.value)}
        />
        <button
          onClick={(e) => props.register(e)}
          disabled={!props.buttonIsClickable}
        >
          הרשמה
        </button>
        <br />
        <a href="#" onClick={() => props.login()}>
          יש לך משתמש? <b>התחברות</b>
        </a>
      </form>
    </div>
  );
};

export default Register;
