import React, { Component } from "react";
import Auth from "../../commons/Auth";
import Loading from "../../commons/Loading/loading";
import Header from "../../commons/Header/header";
import LocationList from "./LocationsList/locationList";
import Users from "./Users/users";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { reduxStore } from "../../index";
import { getAllLocations } from "../../commons/Database";

import classes from "./manage.module.scss";
import "./tabs.scss";
class Manage extends Component {
  state = {
    logged: false,
    loading: true,
    userName: "",
    canAccess: false,
    isManager: false,
    locations: [],
    noLocations: false,
  };

  async componentDidMount() {
    const isLogged = await Auth.isLogged();
    if (!isLogged) {
      window.location.replace("/manage/login");
    }

    const reduxState = await reduxStore.getState();
    const canAccess = reduxState.user;
    if (!canAccess) {
      window.location.replace("/manage/login");
    }
    this.setState({ canAccess });

    const isManager = reduxState.user.role >= 2;

    this.setState({ isManager });
    this.setState({ logged: isLogged });
    const user = await Auth.getUser();
    this.setState({ userName: user.name });

    this.setState({ loading: false });
    getAllLocations().then((locations) => {
      this.setState({ locations });
      if (locations.length === 0) {
        this.setState({ noLocations: true });
      }
    });
  }

  render() {
    return (
      <Loading loading={this.state.loading}>
        {this.state.logged && this.state.canAccess ? (
          <React.Fragment>
            <Header name={this.state.userName} />
            {this.state.isManager ? (
              <Tabs>
                <TabList>
                  <Tab>מיקומים</Tab>
                  <Tab>משתמשים</Tab>
                </TabList>
                <TabPanel>
                  <LocationList
                    locationsListClass={classes.locationsList}
                    newLocationButtonClass={classes.newLocationButton}
                    locations={this.state.locations}
                    noResults={this.state.noLocations}
                  />
                </TabPanel>
                <TabPanel>
                  <Users />
                </TabPanel>
              </Tabs>
            ) : (
              <LocationList
                locationsListClass={classes.locationsList}
                newLocationButtonClass={classes.newLocationButton}
                locations={this.state.locations}
                noResults={this.state.noLocations}
              />
            )}
          </React.Fragment>
        ) : (
          <div style={{ color: "#fff" }}>אין גישה</div>
        )}
      </Loading>
    );
  }
}

export default Manage;
