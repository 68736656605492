import React from "react";
import "./login.scss";

const Login = (props) => {
  return (
    <div className="loginFormContainer login">
      <h1>התחברות</h1>
      <form>
        <label htmlFor="email">כתובת אימייל</label>
        <input
          type="email"
          id="email"
          value={props.email}
          onChange={(e) => props.emailChanged(e.target.value)}
        />
        <label htmlFor="password">סיסמה</label>
        <input
          type="password"
          id="password"
          value={props.password}
          onChange={(e) => props.passwordChanged(e.target.value)}
        />
        <button
          onClick={(e) => props.login(e)}
          disabled={!props.buttonIsClickable}
        >
          התחברות
        </button>
        <br />
        <a href="#" onClick={() => props.register()}>
          אין לך משתמש? <b>הרשמה</b>
        </a>
      </form>
    </div>
  );
};

export default Login;
