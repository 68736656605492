import React from "react";
import classes from "../home.module.scss";

const Window = (props) => {
  return (
    <div className={classes.locationWindow}>
      <button onClick={() => props.closeInfoWindow()}>סגירה</button>
      <img src={props.windowImageSrc} />
      <div className={classes.text}>
        <div className={classes.title}>{props.windowTitle}</div>
        <div className={classes.content}>
          {props.windowContent.split("\n").map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Window;
