import Url from "./Urls";
import axios from "axios";
import { showAlert } from "../helpers/alert";
import { reduxStore } from "../index";
var store = require("store");

export const addLocation = (data) => {
  return new Promise((resolve) => {
    reduxStore.dispatch({ type: "UPDATE_LOCATIONS", payload: null });
    axios
      .post(`${Url}/locations/add`, data, {
        headers: { Authorization: `Bearer ${store.get("token")}` },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};

export const updateLocation = (id, data) => {
  reduxStore.dispatch({ type: "UPDATE_LOCATIONS", payload: null });

  return new Promise((resolve) => {
    axios
      .patch(
        `${Url}/locations/update`,
        { _id: id, ...data },
        {
          headers: { Authorization: `Bearer ${store.get("token")}` },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};

export const deleteLocation = (id) => {
  reduxStore.dispatch({ type: "UPDATE_LOCATIONS", payload: null });

  return new Promise((resolve) => {
    axios
      .post(
        `${Url}/locations/delete`,
        { _id: id },
        {
          headers: { Authorization: `Bearer ${store.get("token")}` },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};

export const addPath = (data) => {
  reduxStore.dispatch({ type: "UPDATE_LOCATIONS", payload: null });

  return new Promise((resolve) => {
    axios
      .post(`${Url}/locations/addpath`, data, {
        headers: { Authorization: `Bearer ${store.get("token")}` },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};

export const imageUpload = (file, id, isPath) => {
  return new Promise((resolve) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("id", id);
    formData.append("isPath", isPath);

    axios
      .post(`${Url}/locations/uploadimage`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.get("token")}`,
        },
      })
      .then((response) => resolve(response.data))
      .catch((err) => resolve(err));
  });
};

export const uploadPreview = (file, id) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${Url}/locations/uploadmappreview`,
        { file, id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.get("token")}`,
          },
        }
      )
      .then((response) => {
        if ("error" in response.data) {
          showAlert(response.data.error, { title: "אופס..." });
        }
        resolve(response.data);
      })
      .catch((err) => resolve(err));
  });
};

export const getAllLocations = () => {
  return new Promise(async (resolve) => {
    const reduxState = await reduxStore.getState();
    if (reduxState.locations) {
      resolve(reduxState.locations);
    } else {
      axios
        .get(`${Url}/locations/getall`)
        .then((response) => response.data)
        .then((data) => {
          reduxStore.dispatch({ type: "UPDATE_LOCATIONS", payload: data });
          resolve(data);
        })
        .catch((err) => resolve(err));
    }
  });
};
export const getLocation = (id) => {
  return new Promise((resolve) => {
    axios
      .get(`${Url}/locations/get?id=${id}`)
      .then((response) => response.data)
      .then((data) => resolve(data))
      .catch((err) => resolve(err));
  });
};

export const getAllUsers = () => {
  return new Promise(async (resolve) => {
    const reduxState = await reduxStore.getState();
    if (reduxState.users) {
      resolve(reduxState.users);
    } else {
      axios
        .post(
          `${Url}/users/getAll`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.get("token")}`,
            },
          }
        )
        .then((response) => {
          if ("error" in response.data) {
            showAlert(response.data.error, { title: "אופס..." });
          }
          reduxStore.dispatch({ type: "UPDATE_USERS", payload: response.data });

          resolve(response.data);
        })
        .catch((err) => resolve(err));
    }
  });
};

export const updateUser = (id, data) => {
  reduxStore.dispatch({ type: "UPDATE_USERS", payload: null });

  return new Promise((resolve) => {
    axios
      .post(
        `${Url}/users/updatebyid`,
        { id, ...data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.get("token")}`,
          },
        }
      )
      .then((response) => {
        if ("error" in response.data) {
          showAlert(response.data.error, { title: "אופס..." });
        }
        resolve(response.data);
      })
      .catch((err) => resolve(err));
  });
};
export const deleteUser = (id) => {
  reduxStore.dispatch({ type: "UPDATE_USERS", payload: null });

  return new Promise((resolve) => {
    axios
      .post(
        `${Url}/users/delete`,
        { id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.get("token")}`,
          },
        }
      )
      .then((response) => {
        if ("error" in response.data) {
          showAlert(response.data.error, { title: "אופס..." });
        }
        resolve(response.data);
      })
      .catch((err) => resolve(err));
  });
};
