const locations = {
  Gaza: {
    location: [34.308826, 31.424675],
    zoom: 10,
    name: "עזה",
    public: true,
  },
  Lebanon: {
    location: [35.462598, 33.577443],
    zoom: 8,
    name: "לבנון",
    public: true,
  },
  Syria: {
    location: [36.106753, 33.197328],
    zoom: 7,
    name: "סוריה",
    public: true,
  },
  Israel: {
    location: [34.979739, 31.758678],
    zoom: 7,
    name: "ישראל",
    public: false,
  },
};

export default locations;
